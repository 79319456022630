import React from "react";
import "../../styles/textla.scss";
import Panel1 from "./panel1";
import Panel2 from "./panel2";

import Layout from "../../components/layout";
import SignUpForm from "../../components/textla/signUpForm";

const TextlaHomePage = () => (
  <Layout className="textla" pageInfo={{ pageName: "textla" }}>
    <Panel1 />
    <Panel2 />
    {/* <SignUpForm /> */}
  </Layout>
);

export default TextlaHomePage;
